import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import CustomButton from '../components/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { createOrder } from '../redux/actions/api'
import { getMe } from '../redux/actions/auth'
import { getCookie } from '../utils/cookies'
import { useLocation } from 'react-router'
import otp from '../assets/simple.jpeg'
import { forintFormat } from '../utils/utils'
import { format } from 'date-fns'
import TextField from '../components/TextField'

const CartSum = () => {
	const { state } = useLocation()
	const dispatch = useDispatch()
	const token = getCookie('token')

	const user = useSelector((state) => state.auth.user)

	const [cart, setCart] = useState([])
	const [sum, setSum] = useState(0)
	const [delivery, setDelivery] = useState('futár')
	const [isEdit, setIsEdit] = useState(false)
	const [isBillingEdit, setIsBillingEdit] = useState(false)
	const [isBillingData, setIsBillingData] = useState(false)
	const [isCheck, setIsCheck] = useState(false)

	const userData = {
		name: user.name,
		email: user.email,
		phone: user.phone,
		address: user.address,
		city: user.city,
		zip: user.zip,
		tax: user.tax,
	}

	const [deliveryContact, setDeliveryContact] = useState(userData)
	const [billingContact, setBillingContact] = useState(userData)

	const [cartOrPlay] = Object.keys(state)

	useEffect(() => {
		if (token) {
			dispatch(getMe())
		}
	}, [token])

	useEffect(() => {
		const { cart, playcart } = state
		cart ? setCart(cart) : setCart(playcart)
	}, [state])

	useEffect(() => {
		let newsum = 0
		cart.map((item) => {
			newsum += Number(item.product.price) * Number(item.count)
		})
		setSum(newsum)
	}, [cart])

	return (
		<Layout active='Kosár'>
			<div className='mx-4 font-bold'>
				<h1 className='text-2xl my-4 lg:my-12 text-center'>Vásárlás</h1>
				<div className='flex flex-col gap-8 lg:flex-row p-4'>
					<div className='flex flex-col w-full lg:1/4 items-center bg-grey rounded-xl p-4'>
						<div className='text-xl font-bold mb-4 py-2 lg:p-0'>Termékek</div>
						<div className='border rounded-xl w-full'>
							{cart.map((item) => (
								<React.Fragment key={item.product.id + item.size + item.color}>
									<div className='w-full mb-2 w-full'>
										<p className='p-2 text-center text-xl font-black'>
											{item.product.title}
										</p>
									</div>
									{cartOrPlay === 'cart' ? (
										<div className='w-full flex justify-around my-2'>
											<div
												className=''
												style={{
													width: 30,
													height: 30,
													borderRadius: 30,
													backgroundColor: item.color,
												}}
											/>
											<p className=' '>{item.size}</p>
										</div>
									) : (
										<div>
											{format(
												new Date(item.product.date),
												'yyyy. LLLL dd. HH:mm'
											)}
										</div>
									)}
									<div className='w-full flex justify-around py-2 border-b'>
										<p>{item.count} darab</p>
										<p>{forintFormat(sum)}</p>
									</div>
								</React.Fragment>
							))}
						</div>
					</div>
					<div className='w-full lg:1/4 p-4 lg:m-0 rounded-xl bg-grey'>
						<div className='text-xl font-bold mb-4 py-2 lg:p-0 text-center'>
							Adatok
						</div>
						<div className='flex flex-row justify-between'>
							<div
								className={`text-center mb-4 border rounded-xl border-white p-4 bg-transparent flex-grow mr-2 ${
									!isBillingData ? 'bg-blue text-black' : 'bg-transparent'
								}`}
								onClick={() => {
									setIsBillingData(!isBillingData)
								}}>
								Szállítási adatok
							</div>
							<div
								className={`text-center mb-4 border rounded-xl border-white p-4 bg-transparent flex-grow ml-2 ${
									isBillingData ? 'bg-blue text-black' : 'bg-transparent'
								}`}
								onClick={() => {
									setIsBillingData(!isBillingData)
								}}>
								Számlázási adatok
							</div>
						</div>

						{!isBillingData ? (
							<div className='w-full grid grid-cols-1 p-4 lg:mt-0 items-center border rounded-xl'>
								{!isEdit ? (
									<>
										<div className='text-center'>Név:</div>
										<div className='text-center text-xl mb-4'>
											{deliveryContact.name}
										</div>
										<div className='text-center'>E-mail:</div>
										<div className='text-center text-xl mb-4'>
											{deliveryContact.email}
										</div>
										<div className='text-center'>Telefonszám:</div>
										<div className='text-center text-xl mb-4'>
											{deliveryContact.phone}
										</div>
										<div className='text-center'>Szállítási cím:</div>
										<div className='text-center text-xl mb-4'>
											<div>
												{deliveryContact.city}, {deliveryContact.zip}
											</div>
											<div>{deliveryContact.address}</div>
										</div>
									</>
								) : (
									<>
										<TextField
											type='text'
											label='Név:'
											name='name'
											value={deliveryContact.name}
											onChange={(event) =>
												setDeliveryContact({
													...deliveryContact,
													name: event.target.value,
												})
											}
										/>
										<TextField
											type='text'
											label='Irányítószám:'
											name='zip'
											value={deliveryContact.zip}
											onChange={(event) =>
												setDeliveryContact({
													...deliveryContact,
													zip: event.target.value,
												})
											}
										/>
										<TextField
											type='text'
											label='Város:'
											name='city'
											value={deliveryContact.city}
											onChange={(event) =>
												setDeliveryContact({
													...deliveryContact,
													city: event.target.value,
												})
											}
										/>
										<TextField
											type='text'
											label='Cím:'
											name='address'
											value={deliveryContact.address}
											onChange={(event) =>
												setDeliveryContact({
													...deliveryContact,
													address: event.target.value,
												})
											}
										/>
										<TextField
											type='text'
											label='Telefonszám:'
											name='phone'
											value={deliveryContact.phone}
											onChange={(event) =>
												setDeliveryContact({
													...deliveryContact,
													phone: event.target.value,
												})
											}
										/>
									</>
								)}
								<div className='text-center'>
									<CustomButton
										onClick={() => {
											setIsEdit(!isEdit)
										}}>
										{isEdit
											? 'Szállítási adtaok mentése'
											: 'Szállítási módósítása'}
									</CustomButton>
								</div>
							</div>
						) : (
							<div className='w-full grid grid-cols-1 p-4 lg:mt-0 items-center border rounded-xl'>
								{!isBillingEdit ? (
									<>
										<div className='text-center'>Név:</div>
										<div className='text-center text-xl mb-4'>
											{billingContact.name}
										</div>
										<div className='text-center'>E-mail:</div>
										<div className='text-center text-xl mb-4'>
											{billingContact.email}
										</div>
										<div className='text-center'>Telefonszám:</div>
										<div className='text-center text-xl mb-4'>
											{billingContact.phone}
										</div>
										<div className='text-center'>Számlázási cím:</div>
										<div className='text-center text-xl mb-4'>
											<div>
												{billingContact.city}, {billingContact.zip}
											</div>
											<div>{billingContact.address}</div>
										</div>
										{billingContact.tax && (
											<>
												<div className='text-center'>Adószám:</div>
												<div className='text-center text-xl mb-4'>
													{billingContact.tax}
												</div>
											</>
										)}
									</>
								) : (
									<>
										<TextField
											type='text'
											label='Név:'
											name='name'
											value={billingContact.name}
											onChange={(event) =>
												setBillingContact({
													...billingContact,
													name: event.target.value,
												})
											}
										/>
										<TextField
											type='text'
											label='E-mail:'
											name='email'
											value={billingContact.email}
											onChange={(event) =>
												setBillingContact({
													...billingContact,
													email: event.target.value,
												})
											}
										/>
										<TextField
											type='text'
											label='Irányítószám:'
											name='zip'
											value={billingContact.zip}
											onChange={(event) =>
												setBillingContact({
													...billingContact,
													zip: event.target.value,
												})
											}
										/>
										<TextField
											type='text'
											label='Város:'
											name='city'
											value={billingContact.city}
											onChange={(event) =>
												setBillingContact({
													...billingContact,
													city: event.target.value,
												})
											}
										/>
										<TextField
											type='text'
											label='Cím:'
											name='address'
											value={billingContact.address}
											onChange={(event) =>
												setBillingContact({
													...billingContact,
													address: event.target.value,
												})
											}
										/>
										<TextField
											type='text'
											label='Telefonszám:'
											name='phone'
											value={billingContact.phone}
											onChange={(event) =>
												setBillingContact({
													...billingContact,
													phone: event.target.value,
												})
											}
										/>
										<TextField
											type='text'
											label='Adószám (cégeknek):'
											name='tax'
											value={billingContact.tax}
											onChange={(event) =>
												setBillingContact({
													...billingContact,
													tax: event.target.value,
												})
											}
										/>
									</>
								)}
								<div className='text-center'>
									<CustomButton
										onClick={() => {
											setIsBillingEdit(!isBillingEdit)
										}}>
										{isBillingEdit
											? 'Számlázási adatok mentése'
											: 'Számlázási adatok módosítása'}
									</CustomButton>
								</div>
							</div>
						)}
					</div>
					{cartOrPlay === 'cart' && (
						<div className='w-full lg:1/4 p-4 rounded-xl bg-grey'>
							<div className='text-xl font-bold mb-4 py-2 lg:p-0 text-center'>
								Szállítási módok
							</div>
							<div
								onClick={() => setDelivery('futár')}
								className={`grid lg:grid-cols-3 text-center gap-1 mb-4 border rounded-xl border-white px-2 py-4 ${
									delivery === 'futár' ? 'bg-blue text-black' : 'bg-transparent'
								}`}>
								<div>Futárszolgálat</div>
								<div />
								<div>{forintFormat(1590)}</div>
							</div>
							<div
								onClick={() => setDelivery('kisesti')}
								className={`grid lg:grid-cols-3 text-center gap-1 mb-4 border rounded-xl border-white p-2 ${
									delivery === 'kisesti'
										? 'bg-blue text-black'
										: 'bg-transparent'
								}`}>
								<div className='my-auto'>Személyes átvétel</div>
								<div>
									<p className='text-xl'>Kisesti</p>
									<p className='text-sm'>(Ráday utca 26. Budapest, 1092.)</p>
								</div>
								<div className='my-auto'>
									<p className='my-auto'>{forintFormat(300)}</p>
									<p className='text-sm'>
										(a helyszínen fizetendő és lefogyasztható)
									</p>
								</div>
							</div>
							<div
								onClick={() => setDelivery('úrimuri')}
								className={`grid lg:grid-cols-3 text-center gap-1 border rounded-xl border-white p-2 ${
									delivery === 'úrimuri'
										? 'bg-blue text-black'
										: 'bg-transparent'
								}`}>
								<div className='my-auto'>Személyes átvétel</div>
								<div>
									<p className='text-xl'>Úri Muri</p>
									<p className='text-sm'>
										(Wesselényi utca 19. Budapest, 1077.)
									</p>
								</div>
								<div className='my-auto'>
									<p className='my-auto'>{forintFormat(300)}</p>
									<p className='text-sm'>
										(a helyszínen fizetendő és lefogyasztható)
									</p>
								</div>
							</div>
						</div>
					)}
					<div className='flex flex-col w-full lg:1/4 items-center p-4 rounded-xl bg-grey'>
						<div>Végösszeg:</div>
						<div className='mb-8 mt-2 text-4xl font-bold'>
							{forintFormat(
								sum + (cartOrPlay === 'cart' && delivery === 'futár' && 1590)
							)}
						</div>
						<CustomButton
							big
							loading={!isCheck}
							onClick={() => {
								const userid = getCookie('userid')
								let sendElement = []

								cart.map((item) => {
									if (cartOrPlay === 'cart') {
										sendElement.push({
											type: item.type,
											productId: item.product.id,
											eventId: null,
											quantity: item.count,
											size: item.size,
											color: item.color,
										})
									} else {
										sendElement.push({
											type: item.type,
											productId: null,
											eventId: item.product.id,
											quantity: item.count,
										})
									}
								})
								const body = {
									access_token: token,
									date: new Date(),
									element: JSON.stringify(sendElement),
									user: userid,
									price:
										sum +
										(cartOrPlay === 'cart' && delivery === 'futár' && 1590),
									status: 'fizetetlen',
									delivery: delivery,
									userid: userid,
									type: cartOrPlay === 'cart' ? 'product' : 'event',
									deliveryContact: deliveryContact,
									billingContact: billingContact,
								}
								dispatch(createOrder(body))
							}}>
							Megrendelés
						</CustomButton>

						<div className='my-4'>
							<input
								type='checkbox'
								className='inline w-4 h-4 bg-blue'
								onClick={() => {
									setIsCheck(!isCheck)
								}}></input>
							<p className='inline p-1'>Elfogadom a fizetési feltételeket</p>
						</div>
						<img
							src={otp}
							alt={'fizetes'}
							className='my-4 w-48 sm:justify-center self-center rounded-xl'
						/>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default CartSum
