import React, { useEffect, useState } from 'react'
import CustomButton from '../components/CustomButton'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'

import Layout from '../components/Layout'
import { getCookie } from '../utils/cookies'
import { rewriteCart, rewritePlayCart } from '../redux/actions/state'
import { forintFormat } from '../utils/utils'

const Cart = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const cart = useSelector((state) => state.state.cart)
	const playcart = useSelector((state) => state.state.playcart)
	const [sum, setSum] = useState(0)
	const [playSum, setPlaySum] = useState(0)

	const token = getCookie('token')

	useEffect(() => {
		let newsum = 0
		cart.map(
			(item) => (newsum += Number(item.product.price) * Number(item.count))
		)
		setSum(newsum)
	}, [cart])

	useEffect(() => {
		let newplaysum = 0
		playcart.map(
			(item) => (newplaysum += Number(item.product.price) * Number(item.count))
		)
		setPlaySum(newplaysum)
	}, [playcart])

	const quantityHandel = (state, item, operator) => {
		const newCart = state.map((e) => {
			if (
				e.product.id === item.product.id &&
				e.color === item.color &&
				e.size === item.size
			) {
				e.count = item.count + operator
				return e
			} else {
				return e
			}
		})
		console.log(`newCart`, newCart)
		state === cart
			? dispatch(rewriteCart(newCart))
			: dispatch(rewritePlayCart(newCart))
	}

	// window.scrollTo(0, 0)

	return (
		<Layout active='Kosár'>
			<div className='mx-8 lg:mx-24 font-bold'>
				<h1 className='text-2xl my-12 text-center'>Kosár</h1>
				{cart.length !== 0 && playcart.length !== 0 && (
					<div>
						<p className='text-xl text-center'>
							Ide kell a szöveg hogy értsék hogy miért van két kassza és ez csak
							akkor jön elő, ha mindkét kosárba van termék
						</p>
					</div>
				)}
				{cart.length !== 0 && (
					<div className='grid grid-cols-2 rounded-xl lg:grid-cols-5 bg-grey lg:p-4 lg:p-8 my-8'>
						<div className='my-2 hidden lg:block'>
							<p className='text-xl text-center '>Termékek</p>
						</div>
						<div className='hidden lg:block my-2'>
							<p className='text-xl text-center '>Szín</p>
						</div>
						<div className='hidden lg:block my-2'>
							<p className='text-xl text-center '>Méret</p>
						</div>
						<div className='hidden lg:block my-2'>
							<p className='text-xl text-center '>Mennyiség</p>
						</div>
						<div className='hidden lg:block my-2'>
							<p className='text-xl text-center '>Ár</p>
						</div>
						<div className='hidden lg:block lg:col-span-5 border-b'></div>
						{cart.map((item) => (
							<React.Fragment key={item.product.id + item.size + item.color}>
								<div className='col-span-2 lg:col-span-1 my-4 lg:my-auto'>
									<p className='text-center text-xl'>{item.product.title}</p>
								</div>
								<div className='my-2 lg:my-auto'>
									<div
										className='mx-auto'
										style={{
											width: 30,
											height: 30,
											borderRadius: 30,
											backgroundColor: item.color,
										}}
									/>
								</div>
								<div className='my-2 lg:my-auto'>
									<p className=' text-center'>{item.size}</p>
								</div>
								<div className='my-2 lg:my-auto'>
									<div className='m-4'>
										<p className=' text-center'>{item.count} darab</p>
									</div>
									<div className='flex justify-center m-4'>
										<div
											className='mx-2 px-2 p-1px w-7 border-blue border rounded hover:bg-blue hover:text-black'
											onClick={() => {
												if (item.count > 1) {
													quantityHandel(cart, item, -1)
												}
											}}>
											<p className='font-bold	text-center'>-</p>
										</div>
										<div
											className='mx-2 px-2 p-1px w-7 border-blue border rounded hover:bg-blue hover:text-black'
											onClick={() => {
												quantityHandel(cart, item, +1)
											}}>
											<p className='font-bold text-center'>+</p>
										</div>
										<button
											className='mx-2 px-2 p-1px w-7 border-blue border rounded hover:bg-blue hover:text-black'
											onClick={() => {
												const newCart = cart.filter(
													(element) => element !== item
												)
												dispatch(rewriteCart(newCart))
											}}>
											<p className='font-bold text-center'>X</p>
										</button>
									</div>
								</div>
								<div className='my-2 lg:my-auto'>
									<p className='text-center'>
										{forintFormat(
											Number(item.product.price) * Number(item.count)
										)}
									</p>
								</div>
								<div className='col-span-2 lg:col-span-5 border-b'></div>
							</React.Fragment>
						))}
						<div className='my-4  lg:col-start-3 lg:col-span-2 my-auto'>
							<p className='text-2xl  text-center lg:text-right text-blue font-bold'>
								Végösszeg: {forintFormat(sum)}
							</p>
						</div>
						<div className='my-4 lg:col-start-5 text-center'>
							<CustomButton
								onClick={() => {
									localStorage.setItem('cart', JSON.stringify(cart))
									localStorage.setItem('playcart', JSON.stringify(playcart))

									token === '' || !token
										? history.push('/kosár/adatok')
										: history.push({
												pathname: '/kosár/összegzés',
												state: { cart: cart },
										  })
								}}>
								Tovább
							</CustomButton>
						</div>
					</div>
				)}

				{playcart.length !== 0 && (
					<div className='grid grid-cols-2 rounded-xl lg:grid-cols-5 bg-grey lg:p-4 lg:p-8 my-8'>
						<div className='my-2 hidden lg:block'>
							<p className='text-xl text-center '>Cím</p>
						</div>
						<div className='hidden lg:block my-2'>
							<p className='text-xl text-center '>idópont</p>
						</div>
						<div className='hidden lg:block my-2'>
							<p className='text-xl text-center '>Helyszín</p>
						</div>
						<div className='hidden lg:block my-2'>
							<p className='text-xl text-center '>Mennyiség</p>
						</div>
						<div className='hidden lg:block my-2'>
							<p className='text-xl text-center '>Ár</p>
						</div>
						<div className='hidden lg:block lg:col-span-5 border-b'></div>
						{playcart.map((item) => (
							<React.Fragment key={item.product.id}>
								<div className='col-span-2 lg:col-span-1 my-4 lg:my-auto'>
									<p className='text-center text-xl'>{item.product.title}</p>
								</div>
								<div className='my-2 lg:my-auto'>
									<p className=' text-center'>
										{format(
											new Date(item.product.date),
											'yyyy. LLLL dd. HH:mm'
										)}
									</p>
								</div>
								<div className='my-2 lg:my-auto'>
									<p className=' text-center'>{item.product.location}</p>
								</div>
								<div className='my-2 lg:my-auto'>
									<div className='m-4'>
										<p className=' text-center'>{item.count} darab</p>
									</div>
									<div className='flex justify-center m-4'>
										<div
											className='mx-2 px-2 p-1px w-7 border-blue border rounded hover:bg-blue hover:text-black'
											onClick={() => {
												if (item.count > 1) {
													quantityHandel(playcart, item, -1)
												}
											}}>
											<p className='font-bold	text-center'>-</p>
										</div>
										<div
											className='mx-2 px-2 p-1px w-7 border-blue border rounded hover:bg-blue hover:text-black'
											onClick={() => {
												quantityHandel(playcart, item, +1)
											}}>
											<p className='font-bold text-center'>+</p>
										</div>
										<button
											className='mx-2 px-2 p-1px w-7 border-blue border rounded hover:bg-blue hover:text-black'
											onClick={() => {
												const newCart = playcart.filter(
													(element) => element !== item
												)
												dispatch(rewritePlayCart(newCart))
											}}>
											<p className='font-bold text-center'>X</p>
										</button>
									</div>
								</div>
								<div className='my-2 lg:my-auto'>
									<p className='text-center'>
										{forintFormat(
											Number(item.product.price) * Number(item.count)
										)}
									</p>
								</div>
								<div className='col-span-2 lg:col-span-5 border-b'></div>
							</React.Fragment>
						))}
						<div className='my-4  lg:col-start-3 lg:col-span-2 my-auto'>
							<p className='text-2xl  text-center lg:text-right text-blue font-bold'>
								Végösszeg: {forintFormat(playSum)}
							</p>
						</div>
						<div className='my-4 lg:col-start-5 text-center'>
							<CustomButton
								onClick={() => {
									localStorage.setItem('cart', JSON.stringify(cart))
									localStorage.setItem('playcart', JSON.stringify(playcart))
									token === '' || !token
										? history.push('/kosár/adatok')
										: history.push({
												pathname: '/kosár/összegzés',
												state: { playcart: playcart },
										  })
								}}>
								Tovább
							</CustomButton>
						</div>
					</div>
				)}
				{cart.length === 0 && playcart.length === 0 && (
					<h1 className='text-2xl my-12 text-center mt-12'>
						Jelenleg üres a kosarad
					</h1>
				)}
			</div>
		</Layout>
	)
}

export default Cart
