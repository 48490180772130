import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import Main from '../screens/Main'
import Products from '../screens/Products'
import Highlight from '../screens/Highlight'
import Connect from '../screens/Connect'
import Events from '../screens/Events'
import Profile from '../screens/Profil'
import Login from '../screens/Login'
import Forgotten from '../screens/Forgotten'

import Cart from '../screens/Cart'
import CartData from '../screens/CartData'
import CartSum from '../screens/CartSum'
// import PlayCart from '../screens/PlayCart'
import PlayCartData from '../screens/PlayCartData'
import PlayCartSum from '../screens/PlayCartSum'
import CartThanks from '../screens/CartThanks'

import Privacy from '../screens/Privacy'
import PrivacyData from '../screens/PrivacyData'
import NewPassword from '../screens/NewPassword'
import { useDispatch, useSelector } from 'react-redux'
import { rewriteCart, rewritePlayCart } from '../redux/actions/state'

const Routes = () => {
	const dispatch = useDispatch()
	const cart = useSelector((state) => state.state.cart)
	const playcart = useSelector((state) => state.state.playcart)

	useEffect(() => {
		if (cart.length === 0) {
			const oldCart = JSON.parse(localStorage.getItem('cart'))
			if (oldCart) {
				dispatch(rewriteCart(oldCart))
			}
		} else {
			localStorage.removeItem('cart')
		}

		if (playcart.length === 0) {
			const oldPlayCart = JSON.parse(localStorage.getItem('playcart'))
			if (oldPlayCart) {
				dispatch(rewritePlayCart(oldPlayCart))
			}
		} else {
			localStorage.removeItem('playcart')
		}
	}, [])

	return (
		<Switch>
			<Route exact path={`/`} component={Highlight} />
			<Route exact path={`/rólunk`} component={Main} />
			<Route path={`/termékek`} component={Products} />
			<Route path={`/csatlakozás`} component={Connect} />
			<Route path={`/előadások`} component={Events} />
			<Route path={`/profil`} component={Profile} />
			<Route path={`/login`} component={Login} />
			<Route path={`/forgotten`} component={Forgotten} />
			<Route path={`/newpass/:code`} component={NewPassword} />

			<Route exact path={`/kosár`} component={Cart} />
			<Route path={`/kosár/adatok`} component={CartData} />
			<Route path={`/kosár/összegzés`} component={CartSum} />
			{/* <Route exact path={`/jegyek`} component={PlayCart} /> */}
			<Route path={`/jegyek/adatok`} component={PlayCartData} />
			<Route path={`/jegyek/összegzés`} component={PlayCartSum} />
			<Route path={`/koszonjuk`} component={CartThanks} />

			<Route path={`/ászf`} component={Privacy} />
			<Route path={`/adatkezelési`} component={PrivacyData} />

			<Route path={`/profil`} component={Profile} />
		</Switch>
	)
}

export default Routes
