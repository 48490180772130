import React from 'react'
import Layout from '../components/Layout'
import img from '../assets/Img/Rolunk/EBRDJ_POLO_FONTOS_038.webp'

const Main = () => {
	console.log(`object`, process.env.PUBLIC_URL)
	return (
		<Layout active='Rólunk'>
			<div className='mx-8 lg:mx-96'>
				<h1 className='text-2xl my-12 text-center font-black'>
					Mi is az én színházam?
				</h1>

				<p className='text-center'>
					Egy színházi előadás egy örökre szóló élmény. Benne marad a
					gondolatainkban, szívünkben és lelkünkben is. Úgy gondoljuk, hogy
					nagyon fontos, hogy segítsünk a kultúrának, a színházaknak úgy, hogy
					közben szép emlékekkel is gazdagodhatunk. Éppen ezért hoztuk létre ezt
					az oldalt. Itt olyan termékeket láthatsz, amelyeken a kedvenc
					előadásod egy-egy pillanata lesz megörökítve. A termék megvásárlásával
					egy nemes célt is támogatsz, ugyanis minden termék árából 20%-ot annak
					a színháznak juttatjuk el, amely színház előadásáról készült
					illusztráció szerepel a terméken (ezt a termék nevében is
					megtalálhatod).Válogass kínálatunkból és támogasd te is kedvenc
					színházadat!
				</p>
				<div className='rounder-xl my-12 max-h-src80'>
					<img
						src={img}
						alt=''
						className='p-1 mx-auto object-contain rounded-xl lg:max-h-src80'></img>
				</div>
			</div>
		</Layout>
	)
}

export default Main
