import { Base64 } from 'js-base64'
import { zip } from 'lodash'
import { getCookie, setCookie, deleteCookie } from '../../utils/cookies'
//import history from '../../utils/history';
const qs = require('query-string')

const apiURL = 'https://enszinhazambackend.herokuapp.com'
const masterKey = '2w7I63elUBAWPBT8V37MY4xFZjFpMJnR'
const axios = require('axios')

export function getToken(email, password) {
	return async (dispatch) => {
		return axios
			.post(
				apiURL + '/auth?access_token=' + masterKey,
				{},
				{
					headers: {
						Authorization: 'Basic ' + Base64.encode(email + ':' + password),
					},
				}
			)
			.then((response) => {
				const role = response.data.user.role
				setCookie('token', response.data.token, 30)
				setCookie('userid', response.data.user.id, 30)
				setCookie('userrole', role, 30)
				dispatch(getMe())
				//history.push('/admin');
				return dispatch({
					type: 'LOGIN_SUCCESS',
					data: response.data,
				})
			})
			.catch(function (error) {
				alert('Hiba, kérlek próbáld újra')
				if (error.response) {
					if (error.response.status === 401) {
						dispatch({ type: 'LOGIN_FAILED', data: 'Hiba a belépésnél' })
					} else {
						dispatch({
							type: 'LOGIN_FAILED',
							data: 'Rossz felhasználónév vagy jelszó',
						})
					}
				}
			})
	}
}

export function logOut() {
	return async (dispatch) => {
		deleteCookie('token')
		deleteCookie('userid')
		deleteCookie('userrole')
		//history.push('/login');
		dispatch({ type: 'LOGOUT' })
	}
}

export function getEmailFPassword(email) {
	return async (dispatch, getState) => {
		var data = qs.stringify({
			email: email,
			link: 'https://webmail.enszinhazam.hu/newpass/',
		})
		return axios
			.post(apiURL + '/password-resets', data, {
				headers: {
					Authorization: 'Bearer ' + masterKey,
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.then((response) => {
				alert('Email elküldve az email címedre')
			})
			.catch(function (error) {
				//console.log(error);
				alert('Hiba, kérlek próbáld újra')
				if (error.response) {
					if (error.response.status === 401) {
						dispatch({
							type: 'UPDATE_PASSWORD_FAILED',
							data: 'Hiba a belépésnél',
						})
					} else {
						dispatch({
							type: 'UPDATE_PASSWORD_FAILED',
							data: 'Rossz felhasználónév vagy jelszó',
						})
					}
				}
			})
	}
}
export function checkFPasswordCode(code) {
	return async (dispatch, getState) => {
		return axios
			.get(apiURL + '/password-resets/' + code, {
				headers: {
					Authorization: 'Basic ' + masterKey,
				},
			})
			.then((response) => {
				//alert("Kód érvényesítve");
			})
			.catch(function (error) {
				alert('Hiba, kérlek próbáld újra')
				if (error.response) {
					if (error.response.status === 401) {
						dispatch({
							type: 'UPDATE_PASSWORD_FAILED',
							data: 'Hiba a belépésnél',
						})
					} else {
						dispatch({
							type: 'PASSW_RESET_ERROR',
						})
					}
				}
			})
	}
}
export function updatePassword(password, code, history) {
	return async (dispatch, getState) => {
		const body = {
			password: password,
		}
		return axios
			.put(apiURL + '/password-resets/' + code, qs.stringify(body), {
				headers: {},
			})
			.then((response) => {
				alert('Sikeresen megváltoztattad a jelszavad')
				history.push('/login')
			})
			.catch(function (error) {
				//console.log(error);
				alert('Valami hiba történt, próbáld újra! ')
				if (error.response) {
					if (error.response.status === 401) {
						dispatch({
							type: 'UPDATE_PASSWORD_FAILED',
							data: 'Hiba a belépésnél',
						})
					} else {
						dispatch({
							type: 'UPDATE_PASSWORD_FAILED',
							data: 'Rossz felhasználónév vagy jelszó',
						})
					}
				}
			})
	}
}

export function getMe() {
	return async (dispatch) => {
		const access_token = getCookie('token')
		return axios
			.get(apiURL + '/users/me', {
				headers: { Authorization: 'Bearer ' + access_token },
			})
			.then((response) => {
				setCookie('userid', response.data.id, 30)

				return dispatch({
					type: 'GET_ME',
					data: response.data,
				})
			})
			.catch(function (error) {
				if (error.response) {
					if (error.response.status === 401) {
						dispatch({ type: 'GET_ME_FAILED', data: 'Hiba a belépésnél' })
					} else {
						dispatch({
							type: 'GET_ME_FAILED',
							data: 'Rossz felhasználónév vagy jelszó',
						})
					}
				}
				dispatch({
					type: 'LOGIN_FAILED',
					data: 'Login failed',
				})
			})
	}
}
export function createUser(
	email,
	password,
	name,
	address,
	phone,
	tax,
	zip,
	city
) {
	return async (dispatch) => {
		const body = {
			email: email,
			password: password,
			name: name,
			tax: tax,
			phone: phone,
			address: address,
			zip: zip,
			city: city,
			access_token: masterKey,
			role: 'user',
		}
		console.log(body)
		return axios
			.post(apiURL + '/users', qs.stringify(body), {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.then((response) => {
				dispatch({
					type: 'REG_SUCCESS',
					data: response.data,
				})
				dispatch({
					type: 'LOGIN_SUCCESS',
					data: response.data,
				})
				alert('Sikeres regisztráció')
				console.log(response)
				dispatch(getToken(email, password))
				setCookie('token', response.data.token, 30)
				setCookie('userid', response.data.user.id, 30)
			})
			.catch(function (error) {
				alert('Hiba, kérlek próbáld újra')
				if (error.response) {
					if (error.response.status === 401) {
						return dispatch({ type: 'REG_FAILED', data: 'Hiba a belépésnél' })
					} else {
						return dispatch({
							type: 'REG_FAILED',
							data: 'Rossz felhasználónév vagy jelszó',
						})
					}
				}
				return dispatch({
					type: 'LOGIN_FAILED',
					data: 'Login failed',
				})
			})
	}
}

export function updateUser(fields) {
	return async (dispatch, getState) => {
		const access_token = getCookie('token')
		const id = getCookie('userid')
		var data = qs.stringify({
			access_token: access_token,
			...fields,
		})
		var config = {
			method: 'PUT',
			url: apiURL + '/users/' + id,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			data: data,
		}
		console.log(config)

		return axios(config)
			.then(function (response) {
				alert('Sikeres frissítés')

				console.log(response)
			})
			.catch(function (error) {
				console.log(error)
			})
	}
}
