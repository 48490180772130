import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import CustomButton from '../components/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { createOrder } from '../redux/actions/api'
import { getMe } from '../redux/actions/auth'
import { getCookie } from '../utils/cookies'
import { useHistory, useLocation } from 'react-router'
import otp from '../assets/simple.jpeg'

const CartSum = () => {
	const { state } = useLocation()
	const dispatch = useDispatch()
	const history = useHistory()
	const user = useSelector((state) => state.auth.user)

	const [sum, setSum] = useState(0)
	const [cart, setCart] = useState([])

	console.log(`location`, state)

	useEffect(() => {
		dispatch(getMe())
		const loacalCart = localStorage.getItem('playcart')
		if (!loacalCart) {
			history.push('/jegyek')
		} else {
			try {
				setCart(JSON.parse(loacalCart))
			} catch (e) {
				console.log(loacalCart)
				console.log(e)
			}
		}
	}, [])

	useEffect(() => {
		let newsum = 0
		cart.map(
			(item) => (newsum += Number(item.product.price) * Number(item.count))
		)
		setSum(newsum)
	}, [cart])

	return (
		<Layout active='Kosár'>
			<div className='mx-8 lg:mx-24 font-bold'>
				<h1 className='text-2xl my-12 text-center'>Vásárlás</h1>
				<div className='flex flex-col bg-grey p-12'>
					<div className='flex flex-col lg:flex-row justify-between'>
						<div className='flex flex-col w-full lg:w-1/3 items-center'>
							<div className='text-xl font-bold mb-4 lg:mb-0'>Termékek:</div>
							{cart.map((item) => (
								<div
									key={item.id}
									className='flex flex-col lg:flex-row pb-4 border-b text-center'>
									<p>
										{item.product.title}
										<strong className='hidden lg:inline font-base'>
											{' - '}
										</strong>
										<br className='lg:hidden' />
										{item.count}
										{' db'}
									</p>
								</div>
							))}
						</div>
						<div className='flex flex-col w-full lg:w-1/3 mt-4 lg:mt-0 items-center'>
							<div>Számlázási cím:</div>
							<div>{user.zip}</div>
							<div>{user.city}</div>
							<div>{user.address}</div>
							<div>Telefonszám:</div>
							<div>{user.phone}</div>
						</div>
						<div className='flex flex-col w-full lg:w-1/3 mt-4 lg:mt-0 items-center'>
							<div className='mb-4'>Végösszeg: {sum} Ft</div>
							<CustomButton
								onClick={() => {
									const token = getCookie('token')
									const userid = getCookie('userid')
									var totalprice = 0
									var sendElement = []

									cart.map((item) => {
										totalprice +=
											Number(item.product.price) * Number(item.count)
										console.log(item)
										if (item.size !== '' && item.size) {
											sendElement.push({
												type: item.type,
												productId: item.product.id,
												eventId: null,
												quantity: item.count,
												size: item.size,
												color: item.color,
											})
										} else {
											sendElement.push({
												type: item.type,
												productId: null,
												eventId: item.product.id,
												quantity: item.count,
											})
										}
									})
									const body = {
										access_token: token,
										date: new Date(),
										element: JSON.stringify(sendElement),
										user: userid,
										price: totalprice,
										status: 'fizetetlen',
										userid: userid,
										type: 'event',
									}
									dispatch(createOrder(body))
								}}>
								Megrendelés
							</CustomButton>
							<img
								src={otp}
								alt={'fizetes'}
								className='w-24 sm:justify-center self-center rounded'
							/>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default CartSum
