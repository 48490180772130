import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import CookieConsent from 'react-cookie-consent'

const Layout = ({ children, active }) => {
	return (
		<div className='min-h-screen'>
			<Navbar active={active} />
			<div className='bg-bg-black  flex flex-col justify-between min-h-screen'>
				<div className='pt-20 pb-20 lg:pt-56 flex flex-col text-white'>
					{children}
				</div>
				<CookieConsent buttonText='Rendben van'>
					A honlap cookie-kat használ a megfelelő működés érdekében
				</CookieConsent>
				<Footer />
			</div>
		</div>
	)
}

export default Layout
