import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Hamburger from 'hamburger-react'
import logo from '../../assets/logo.png'
import profil from '../../assets/user.png'
import cart from '../../assets/cart.png'
import './index.css'

const Navbar = ({ active }) => {
	const [isOpen, setOpen] = useState(false)
	const [scrollPosition, setScrollPosition] = useState(0)
	const handleScroll = () => {
		const position = window.scrollY
		setScrollPosition(position)
	}
	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true })

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])
	return (
		<div>
			<div className='backgroundImage fixed w-auto top-0 left-0 right-0 z-50 h-auto bg-bg-black border-b-4 border-red'>
				<div className='hidden lg:block h-auto mx-40 text-blue'>
					<div className='flex items-center justify-center'>
						{scrollPosition < 50 && (
							<Link to='/'>
								<img
									style={{
										width: 230 / 2 - scrollPosition * 0.2,
										height: 193 / 2 - scrollPosition * 0.2,
									}}
									alt='logo'
									src={logo}
									className='h-24 w-auto my-12 cursor-pointer'
								/>
							</Link>
						)}
					</div>
					<div className='grid grid-cols-5 h-12 items-center font-black'>
						<Link
							to='/rólunk'
							className={`mx-auto ${active === 'Rólunk' && 'text-red'}`}>
							Rólunk
						</Link>

						<Link
							to='/termékek'
							className={`mx-auto ${active === 'Termékek' && 'text-red'}`}>
							Termékek
						</Link>
						<Link
							to='/csatlakozás'
							className={`mx-auto ${active === 'Csatlakozás' && 'text-red'}`}>
							Hogyan csatlakozhatsz?
						</Link>
						<Link
							to='/előadások'
							className={`mx-auto ${active === 'Előadások' && 'text-red'}`}>
							Előadások
						</Link>
						<div className='flex flex-row mx-auto '>
							<Link
								to='/profil'
								className={`${active === 'Profil' && 'filter invert'}`}>
								<img alt='Profil' src={profil} className='h-8 w-8 mr-4' />
							</Link>
							<Link
								to='/kosár'
								className={`${active === 'Kosár' && 'filter invert'}`}>
								<img alt='Kosár' src={cart} className='h-8 w-8' />
							</Link>
						</div>
					</div>
				</div>
				<div className='block lg:hidden flex flex-row h-16 mx-12 justify-between items-center text-white'>
					<Link to='/'>
						<img alt='ÉnSzínházam' src={logo} className='h-10 cursor-pointer' />
					</Link>
					<Hamburger toggled={isOpen} toggle={setOpen} />
				</div>
			</div>
			{isOpen && (
				<div className='block lg:hidden'>
					<div className='fixed top-0 left-0 right-0 mt-16 bg-grey p-4 font-black rounded-b-3xl'>
						<div className='w-full flex flex-col items-start text-blue ml-8'>
							<Link to='/rólunk'>
								<p className={`py-4 ${active === 'Rólunk' && 'text-red'}`}>
									Rólunk
								</p>
							</Link>
							<Link
								to='/termékek'
								className={`py-4 ${
									active === 'Termékek' && 'text-red'
								} cursor-pointer	`}>
								Termékek
							</Link>
							<Link
								to='/csatlakozás'
								className={`py-4 ${active === 'Csatlakozás' && 'text-red'}`}>
								Hogyan csatlakozhatsz?
							</Link>
							<Link
								to='/előadások'
								className={`py-4 ${active === 'Előadások' && 'text-red'}`}>
								Előadások
							</Link>
							<Link
								to='/profil'
								className={`py-4 ${active === 'Profil' && 'text-red'}`}>
								Profil
							</Link>
							<Link
								to='/kosár'
								className={`py-4 ${active === 'Kosár' && 'text-red'}`}>
								Kosár
							</Link>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default Navbar
