import React from 'react'
import { Link } from 'react-router-dom'
import facebook from '../../assets/fb.png' // Tell webpack this JS file uses this image
import insta from '../../assets/insta.png' // Tell webpack this JS file uses this image

const Footer = () => {
	return (
		<div className='relative bottom-0 left-0 bg-grey p-8 text-white font-black text-sm'>
			<div className='flex flex-row mb-2 flex-wrap mx-0 lg:mx-24 text-center justify-center items-center'>
				<a href='https://www.facebook.com/enszinhazam' target='blank'>
					<img src={facebook} alt='HTML tutorial' className={'w-6 m-1'} />
				</a>
				<a href='https://www.instagram.com/enszinhazam/' target='blank'>
					<img src={insta} alt='HTML tutorial' className={'w-6 m-1'} />
				</a>
			</div>

			<div className='flex flex-col lg:flex-row flex-wrap mx-0 lg:mx-24 text-center justify-center items-center'>
				<p className='mr-4'>E-mail: info@enszinhazam.hu</p>
				<p className='mr-4'>
					Ügyfélszolgálat: +36704271314 (Munkanapokon, 10-17 óra között)
				</p>
			</div>
			<div className='flex flex-col lg:flex-row flex-wrap mx-0 lg:mx-24 text-center justify-center items-center'>
				<Link to='/ászf' className='mr-4 mt-4 lg:mt-0'>
					Ászf
				</Link>
				<Link to='/adatkezelési' className='mr-4'>
					Adatkezelési tájékoztató
				</Link>
			</div>
		</div>
	)
}

export default Footer
