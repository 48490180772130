import React from 'react'
import Layout from '../components/Layout'
import connect from '../assets/Img/Csatlakozz/EBRDJ_POLO_FONTOS_041.webp'

const Connect = () => {
	return (
		<Layout active='Csatlakozás'>
			<div className='mx-4 lg:mx-24'>
				<h1 className='text-2xl my-12 text-center font-bold'>
					Hogyan csatlakozhatsz?
				</h1>
				<div className='flex flex-col lg:flex-row w-full lg:mx-4'>
					<div className='w-full lg:w-1/3 my-8 lg:mx-4'>
						<h2 className='text-blue text-center text-lg mt-4 lg:mt-0 mb-8'>
							Színházként, Társulatként
						</h2>
						<p className='text-center'>
							További színházak és független társulatok jelentkezését várjuk,
							amennyiben szeretnének csatlakozni az énszínházamhoz. Kérjük küldj
							nekünk egy levelet a{' '}
							<a
								className='text-blue'
								href='mailto: jelentkezem@enszinhazam.hu?subject=Jegyek értékesítése&amp;body=Kedves ÉnSzínházam,'
								target='_blank'
								rel='noopener noreferrer'>
								{' '}
								jelentkezem@enszinhazam.hu
							</a>{' '}
							e-mail címre, és felvesszük veletek a kapcsolatot.
						</p>
					</div>
					<div className='w-full lg:w-1/3 my-8 lg:mx-4'>
						<h2 className='text-blue text-center text-lg my-8 lg:mt-0'>
							Jegyek értékesítése
						</h2>
						<p className='text-center'>
							Olyan színházak és független társulatok jelentkezését is várjuk,
							akik a megszokottnál kedvezményesebb és egyszerűbb feltételekkel
							szeretnék árusítani jegyeiket előadásaikra. Bővebb információért
							küldj levelet a
							<a
								className='text-blue'
								href='mailto: jelentkezem@enszinhazam.hu?subject=Jegyek értékesítése&amp;body=Kedves ÉnSzínházam,'
								target='_blank'
								rel='noopener noreferrer'>
								{' '}
								jelentkezem@enszinhazam.hu
							</a>{' '}
							e-mail címre.
						</p>
					</div>
					<div className='w-full lg:w-1/3 my-8 lg:mx-4'>
						<h2 className='text-blue text-center text-lg mt-4 lg:mt-0 mb-8'>
							Termékgyártóként
						</h2>
						<p className='text-center'>
							Fontos számunkra, hogy fiatal alkotókat is támogathassunk, ezért
							ha van ötleted, hogy hogyan tud kapcsolódni a te terméked az
							énszínházamhoz, és szeretnéd nálunk értékesíteni, kérünk küldj egy
							bemutatkozó levelet, a
							<a
								className='text-blue'
								href='mailto: jelentkezem@enszinhazam.hu?subject=Termékgyártóként jelentkezem&amp;body=Kedves ÉnSzínházam,'
								target='_blank'
								rel='noopener noreferrer'>
								{' '}
								jelentkezem@enszinhazam.hu
							</a>{' '}
							e-mail címre.
						</p>
					</div>
				</div>
				<div className=' mt-16'>
					<img
						alt='csoportkép'
						src={connect}
						className='p-1 mx-auto object-contain rounded-xl lg:max-h-src80'
					/>
				</div>
			</div>
		</Layout>
	)
}

export default Connect
