import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProducts } from '../redux/actions/api'
import Layout from '../components/Layout'
import ProductCard from '../components/ProductCard'

function onlyUnique(value, index, self) {
	return self.indexOf(value) === index
}

const Products = () => {
	const dispatch = useDispatch()
	const [showProduct, setShowProduct] = useState(false)
	const [selectedProduct, setSelectedProduct] = useState({})
	const [selectedCategory, setSelectedCategory] = useState('Pólók')
	const products = useSelector((state) => state.api.products)
	const sectionList = products.map((e) => e.type).filter(onlyUnique)

	useEffect(() => {
		dispatch(getProducts())
		setSelectedProduct({})
	}, [])

	return (
		<Layout active='Termékek'>
			<div className='mx-8 lg:mx-24'>
				{!showProduct && (
					<div>
						<div className='flex flex-row flex-wrap justify-center items-center text-white text-center -m-2'>
							{sectionList.map((item) => (
								<h1
									key={item}
									onClick={() => setSelectedCategory(item)}
									style={{
										color: selectedCategory !== item ? '#6AE6FB' : '#EA5656',
									}}
									className={
										'text-xl mr-5 text-center my-12 font-bold cursor-pointer'
									}>
									{item}
								</h1>
							))}
						</div>

						<div className='flex flex-row flex-wrap justify-center items-center text-white text-center -m-2'>
							{products.map((item) => {
								let image
								if (item.type !== selectedCategory) return

								if (item.img) image = item.img.split(';')[0]
								// ha az utolsó üres
								return (
									<div
										key={item.id}
										className='bg-grey p-8 w-full lg:w-80 h-72 m-2 rounded'
										onClick={() => {
											setShowProduct(true)
											setSelectedProduct(item)
											// setSelectedProduct(item);
										}}>
										<img
											src={image}
											alt={item.title}
											className='w-full lg:w-80 h-auto rounded'
										/>
										<div className='mt-4 font-bold'>{item.title}</div>
									</div>
								)
							})}
						</div>
					</div>
				)}
				{showProduct && (
					<div className='pb-12'>
						<div className='grid grid-cols-3 my-12'>
							<div
								className='text-left col-start-1 cursor-pointer'
								onClick={() => setShowProduct(false)}>
								<p className='text-2xl font-bold'>Vissza</p>
							</div>
							<h1 className='text-center col-start-2 text-2xl font-bold'>
								Vásárlás
							</h1>
						</div>
						<ProductCard product={selectedProduct} />
					</div>
				)}
			</div>
		</Layout>
	)
}

export default Products
