import React, { useEffect } from 'react'
import Layout from '../components/Layout'
// import CustomButton from '../components/CustomButton'
import { useHistory } from 'react-router-dom'
import LoginForm from '../components/LoginForm'
import RegForm from '../components/RegForm'
import { useSelector } from 'react-redux'
import { getCookie } from '../utils/cookies'

const CartData = () => {
	// const dispatch = useDispatch()
	const history = useHistory()
	const token = getCookie('token')
	const reduxtoken = useSelector((state) => state.auth.token)
	// const user = useSelector((state) => state.auth.user)

	useEffect(() => {
		if (token) {
			history.push('/jegyek/összegzés')
		}
	}, [token, reduxtoken])

	return (
		<Layout active='Kosár'>
			<div className='mx-8 lg:mx-24 flex flex-col lg:flex-row justify-between'>
				<LoginForm play />
				<div className='my-0 lg:my-20 h-auto bg-white w-px rounded-full' />
				<RegForm play />
			</div>
		</Layout>
	)
}

export default CartData
