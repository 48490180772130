import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import { getMe } from '../redux/actions/auth'
import { rewriteCart, rewritePlayCart } from '../redux/actions/state'
import { getCookie } from '../utils/cookies'

const CartThanks = () => {
	const dispatch = useDispatch()

	const token = getCookie('token')
	const user = useSelector((state) => state.auth.user)

	const [status, setStatus] = useState()
	const [type, setType] = useState()
	const [circleCounter, setCircleCounter] = useState(0)
	const [message, setMessage] = useState('Rendelésed feldogozás alatt...')

	useEffect(() => {
		if (token) {
			dispatch(getMe())
		}
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			if (token && (!status || status === 'fizetetlen')) {
				dispatch(getMe())
				setCircleCounter(circleCounter + 1)
			}
		}, 3000)
		return () => clearTimeout(timer)
	}, [circleCounter])

	useEffect(() => {
		if (user.orders) {
			console.log(`object`, user.orders)
			const { status, type } = user.orders[user.orders.length - 1]
			setType(type)

			if (circleCounter === 10 && status === 'fizetetlen') {
				setStatus('elutasított')
			} else {
				setStatus(status)
			}
		}
	}, [user])

	console.log(`status`, status, type, circleCounter)

	useEffect(() => {
		if (status === 'fizetett') {
			if (type === 'product') {
				localStorage.removeItem('cart')
				dispatch(rewriteCart([]))
			} else if (type === 'event') {
				localStorage.removeItem('playcart')
				dispatch(rewritePlayCart([]))
			}
			setMessage('Köszönjük a vásárlást!')
		} else if (status === 'elutasított') {
			setMessage('Sikertelen vásárlás!')
		}
	}, [status])

	return (
		<Layout active='Kosár'>
			<div className='mx-24'>
				<h1 className='text-2xl mt-32 text-center font-bold'>{message}</h1>
			</div>
		</Layout>
	)
}

export default CartThanks
