import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import TextField from './TextField'
import CustomButton from './CustomButton'
import { getToken } from '../redux/actions/auth'
import { useHistory } from 'react-router'

const LoginForm = ({ order, play }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	return (
		<div className='mt-12 w-full lg:w-5/12 flex flex-col items-center'>
			<h2 className='text-2xl font-bold'>Bejelentkezés</h2>
			<TextField id='loginEmail' type='text' label='E-mail cím:' name='email' value={email} onChange={(event) => setEmail(event.target.value)} />
			<TextField id='loginPassword' type='password' label='Jelszó:' name='password' value={password} onChange={(event) => setPassword(event.target.value)} />
			<CustomButton onClick={() => history.push('/forgotten')}>Elfelejtett jelszó</CustomButton>
			<CustomButton big onClick={() => dispatch(getToken(email, password))}>
				{order || play ? 'Tovább' : 'Küldés'}
			</CustomButton>
		</div>
	)
}

export default LoginForm
