import React from 'react'

import { format } from 'date-fns'
import { forintFormat } from '../utils/utils'

const OrderCard = ({ order }) => {
	if (typeof order === 'string') return <div />

	return (
		<div className='p-4 lg:p-8 mb-8 flex flex-col lg:flex-row text-white lg:justify-between bg-grey rounded-xl'>
			<div className='w-full flex flex-col border rounded-xl'>
				<div className='flex justify-around my-2'>
					<p>{format(new Date(order?.date), 'yyyy. LLLL. dd. hh:mm')}</p>
					<p className='capitalize'>{order.status}</p>
				</div>
				<div className='w-full border-b'></div>
				{order.element.map((item) => {
					if (item.eventId === null && item.productId) {
						return (
							<React.Fragment key={item.productId.id + item.size + item.color}>
								<div className='my-4'>
									<p className='text-center text-xl font-black'>
										{item.productId.title}
									</p>
								</div>
								<div className='flex justify-around my-2'>
									<div
										className=''
										style={{
											width: 30,
											height: 30,
											borderRadius: 30,
											backgroundColor: item.color,
										}}
									/>
									<p className=' '>{item.size}</p>
								</div>
								<div className='flex justify-around my-2'>
									<p className=' '>{item.quantity} darab</p>

									<p className=''>
										{forintFormat(
											Number(item.productId.price) * Number(item.quantity)
										)}
									</p>
								</div>
								<div className='border-b'></div>
							</React.Fragment>
						)
					}
					if (item.productId === null && item.eventId) {
						return (
							<React.Fragment key={item.eventId.id}>
								<div className='my-4'>
									<p className='text-center text-xl font-black'>
										{item.eventId.title}
									</p>
								</div>
								<div className='my-2'>
									<p className='text-center'>
										{format(
											new Date(item.eventId.date),
											'yyyy. LLLL dd. HH:mm'
										)}
									</p>
								</div>
								<div className='my-2'>
									<p className='text-center'>{item.eventId.location}</p>
								</div>
								<div className='flex justify-around my-2'>
									<p>{item.quantity} darab</p>
									<p>
										{forintFormat(
											Number(item.eventId.price) * Number(item.quantity)
										)}
									</p>
								</div>
								<div className='border-b'></div>
							</React.Fragment>
						)
					}
				})}
				<div className='flex justify-around font-black text-xl my-2'>
					<p>Összesen:</p>
					<p>{forintFormat(order?.price)}</p>
				</div>
			</div>
		</div>
	)
}

export default OrderCard
