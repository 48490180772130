import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getEmailFPassword } from "../redux/actions/auth";
import Layout from "../components/Layout";
import TextField from "../components/TextField";
import CustomButton from "../components/CustomButton";

const Forgotten = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  return (
    <Layout active="Profil">
      <div className="mx-24">
        <div className="mt-12 flex flex-col items-center w-5/12 mx-auto">
          <h2 className="text-2xl font-bold">Elfelejtett jelszó</h2>
          <TextField
            type="text"
            label="E-mail cím:"
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <CustomButton onClick={() => dispatch(getEmailFPassword(email))}>
            Küldés
          </CustomButton>
        </div>
      </div>
    </Layout>
  );
};

export default Forgotten;
