import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEvents } from '../redux/actions/api'
import Layout from '../components/Layout'
import PlayCard from '../components/PlayCard'
import CustomButton from '../components/CustomButton'
import { format } from 'date-fns'

const Events = () => {
	const dispatch = useDispatch()
	const events = useSelector((state) => state.api.events)
	const [showProduct, setShowProduct] = useState(false)
	const [selectedEvent, setSelectedEvent] = useState(false)

	useEffect(() => {
		dispatch(getEvents())
	}, [])

	return (
		<Layout active='Előadások'>
			<div className='mx-8 lg:mx-24 mb-12'>
				{!showProduct && (
					<div>
						<h1 className='text-2xl text-center my-12 font-bold'>Előadások</h1>
						{events.map((item) => {
							return (
								<div
									key={item.title + item.date}
									onClick={() => {
										setShowProduct(true)
										setSelectedEvent(item)
									}}
									className='bg-grey p-2 grid rounded grid-cols-1 md:grid-cols-4 justify-center text-white mb-8'>
									<img
										src={item.img.split(';')[0]}
										alt={item.title}
										className=' md:w-1/2 md:p-1 max-h-72 w-auto mx-auto object-contain'
									/>
									<div className='col-span-2 p-2 flex flex-col items-center justify-center'>
										<div className='text-2xl font-bold text-center'>
											{item.title}
										</div>
										<div className='my-4'>
											{format(new Date(item.date), 'yyyy. LLLL dd. HH:mm')}
										</div>
										<div className='text-center'>{item.location}</div>
									</div>
									<div className='flex flex-col items-center md:items-end justify-center mt-2 md:mt-0'>
										<CustomButton
											// big
											className='self-end'
											type='submit'
											onClick={() => {
												setShowProduct(true)
												setSelectedEvent(item)
											}}>
											Jegyvásárlás
										</CustomButton>
										{new Date(item.date) > new Date() ? (
											item.soldout === 'true' ? (
												<div>Elfogyott</div>
											) : (
												<CustomButton
													// big
													className='self-end'
													type='submit'
													onClick={() => {
														setShowProduct(true)
														setSelectedEvent(item)
													}}>
													Információk
												</CustomButton>
											)
										) : (
											<div>Múltbeli esemény</div>
										)}
									</div>
								</div>
							)
						})}
					</div>
				)}
				{showProduct && (
					<div className='pb-12'>
						<div className='grid grid-cols-3 my-12'>
							<div
								className='text-left col-start-1 cursor-pointer'
								onClick={() => setShowProduct(false)}>
								<p className='text-2xl font-bold'>Vissza</p>
							</div>
							<h1 className='text-center col-start-2 text-2xl font-bold'>
								Előadások
							</h1>
						</div>
						<PlayCard product={selectedEvent} />
					</div>
				)}
			</div>
		</Layout>
	)
}

export default Events
