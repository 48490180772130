import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { logOut, getMe, updateUser } from '../redux/actions/auth'
import Layout from '../components/Layout'
import { getCookie } from '../utils/cookies'
import OrderCard from '../components/OrderCard'
import TextField from '../components/TextField'
import CustomButton from '../components/CustomButton'

const Profil = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const token = getCookie('token')
	const reduxtoken = useSelector((state) => state.auth.token)
	const user = useSelector((state) => state.auth.user)
	const [name, setName] = useState('')
	const [address, setAddress] = useState('')
	const [phone, setPhone] = useState('')
	const [tax, setTaxNumber] = useState('')
	const [zip, setZip] = useState('')
	const [city, setCity] = useState('')
	const [orders, setOrders] = useState([])
	const email = user?.email || ''

	console.log(`token`, token)

	useEffect(() => {
		if (token) {
			dispatch(getMe())
		}
		if (user) {
			setName(user.name)
			setAddress(user.address)
			setPhone(user.phone)
			setTaxNumber(user.tax)
			setCity(user.city)
			setZip(user.zip)
		}
	}, [])

	useEffect(() => {
		if (token) {
			dispatch(getMe())
		}
	}, [token, reduxtoken])

	useEffect(() => {
		if (user) {
			setName(user.name)
			setAddress(user.address)
			setPhone(user.phone)
			setTaxNumber(user.tax)
			setCity(user.city)
			setZip(user.zip)
		}
	}, [user])

	useEffect(() => {
		if (!token) {
			history.push('/login')
		}
	}, [token, reduxtoken])

	useEffect(() => {
		if (user.orders) {
			setOrders(user.orders.reverse())
		}
	}, [user.orders])

	return (
		<Layout active='Profil'>
			<div className='mx-4 lg:mx-24'>
				<div className='lg:mt-8 flex items-end justify-end'>
					<div
						onClick={() => dispatch(logOut())}
						className='text-red text-lg font-bold'>
						Kijelentkezés
					</div>
				</div>
				<div className='flex flex-col mt-4 lg:mt-0 lg:flex-row justify-between'>
					<div className='flex flex-col items-center w-full lg:w-5/12'>
						<div className='text-2xl font-bold'>Profil</div>
						<p>{email}</p>
						<TextField
							// defaultValue='hello'
							type='text'
							label='Név:'
							name='name'
							value={name}
							onChange={(event) => setName(event.target.value)}
						/>

						<TextField
							// defaultValue='hello'
							type='text'
							label='Irányítószám:'
							name='zip'
							value={zip}
							onChange={(event) => setZip(event.target.value)}
						/>
						<TextField
							// defaultValue='hello'
							type='text'
							label='Város:'
							name='city'
							value={city}
							onChange={(event) => setCity(event.target.value)}
						/>
						<TextField
							// defaultValue='hello'
							type='text'
							label='Cím:'
							name='address'
							value={address}
							onChange={(event) => setAddress(event.target.value)}
						/>
						<TextField
							// defaultValue='hello'
							type='text'
							label='Telefonszám:'
							name='phone'
							value={phone}
							onChange={(event) => setPhone(event.target.value)}
						/>
						<TextField
							// defaultValue='hello'
							type='text'
							label='Adószám (cégeknek):'
							name='tax'
							value={tax}
							onChange={(event) => setTaxNumber(event.target.value)}
						/>
						<CustomButton
							onClick={() =>
								dispatch(
									updateUser({ email, name, address, phone, tax, city, zip })
								)
							}>
							Módosítás
						</CustomButton>
					</div>
					<div className='hidden lg:block my-0 lg:my-20 h-auto bg-white w-px rounded-full' />
					<div className='w-full lg:w-5/12 mt-8 lg:mt-0'>
						<div>
							<p className='text-center text-2xl font-bold'>
								Korábbi megrendeléseid
							</p>
						</div>
						<div className='flex flex-col mt-12'>
							{user &&
								user.orders &&
								user.orders.length !== 0 &&
								orders.map((item) => <OrderCard key={item.id} order={item} />)}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Profil
