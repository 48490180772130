import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { format } from 'date-fns'

import CustomButton from './CustomButton'
import { addToPlayCart } from '../redux/actions/state'
import { RollImages } from './RollImages'
import { forintFormat } from '../utils/utils'

const PlayCard = ({ product }) => {
	const dispatch = useDispatch()
	const history = useHistory()

	const [count, setCount] = useState(1)

	const descript = product.description.split(';;')

	return (
		<div className='bg-grey p-1 lg:p-8 grid rounded grid-cols-1 lg:grid-cols-4 justify-center text-white mb-8'>
			<div className='lg:col-span-4 lg:p-8 mt-4 lg:mt-0 text-center'>
				<div className='text-2xl font-black '>{product.title}</div>
				<div>{format(new Date(product.date), 'yyyy. LLLL dd. HH:mm')}</div>
				<div>{product.location}</div>
			</div>
			<div className='lg:col-span-2'>
				<RollImages img={product.img} title={product.title} />
			</div>
			<div className='lg:row-span-2 my-1 mx-2'>
				<div dangerouslySetInnerHTML={{ __html: descript[0] }} />
			</div>
			<div className='row-start-5 lg:row-span-2 flex flex-col items-center justify-center lg:justify-center mt-2 lg:mt-0'>
				<div className='font-black text-2xl mb-4 lg:mb-0'>
					{forintFormat(product.price)}
				</div>
				<div className='flex flex-row'>
					<div
						className='text-5xl font-black'
						onClick={() => {
							if (count > 1) {
								setCount(count - 1)
							}
						}}>
						-
					</div>
					<div className='text-5xl text-blue font-black mx-4'>{count}</div>
					<div
						className='text-5xl font-black'
						onClick={() => setCount(count + 1)}>
						+
					</div>
				</div>
				<div className='items-center'>
					<div className='w-24' />
					<CustomButton
						big
						type='submit'
						className='w-full'
						onClick={() => {
							const cartProduct = {
								count: count,
								product: product,
							}
							dispatch(addToPlayCart(cartProduct))
							history.push('/kosár')
						}}>
						Vásárlás
					</CustomButton>
				</div>
			</div>
			<div className='my-8 mx-8 text-center lg:col-span-2'>
				<div dangerouslySetInnerHTML={{ __html: descript[1] }} />
			</div>
		</div>
	)
}

export default PlayCard
