import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProducts } from '../redux/actions/api'
import Layout from '../components/Layout'
import ProductCard from '../components/ProductCard'

const Products = () => {
	const dispatch = useDispatch()
	const [showProduct, setShowProduct] = useState(false)
	const [selectedProduct, setSelectedProduct] = useState({})

	const products = useSelector((state) => state.api.products)

	useEffect(() => {
		dispatch(getProducts())
	}, [])

	return (
		<Layout active='Highlight'>
			<div className='mx-8 lg:mx-24'>
				{!showProduct && (
					<div>
						<div className='flex flex-row flex-wrap mt-8 justify-center items-center text-white text-center -m-2'>
							{products.map((item) => {
								if (item.highlight !== 'true') return
								return (
									<div
										key={item.id}
										className='bg-grey p-8 w-full lg:w-80 h-72 m-2 rounded'
										onClick={() => {
											setShowProduct(true)
											setSelectedProduct(item)
										}}>
										<img
											src={item.img.split(';')[0]}
											alt={item.title}
											className='w-full lg:w-80 h-auto rounded'
										/>
										<div className='mt-4 font-bold'>{item.title}</div>
									</div>
								)
							})}
						</div>
					</div>
				)}
				{showProduct && (
					<div className='pb-12'>
						<div className='flex flex-row my-12 justify-between'>
							<div
								className='cursor-pointer'
								onClick={() => setShowProduct(false)}>
								Vissza
							</div>
							<h1 className='text-2xl font-bold'>Vásárlás</h1>
							<div />
						</div>
						<ProductCard product={selectedProduct} />
					</div>
				)}

				{/*products && products.result.length !== 0 && products.result.map((item) => {
            return (
                <ProductCard product={item}/>
            )
        })*/}
			</div>
		</Layout>
	)
}

export default Products
