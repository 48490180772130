import React from 'react'
import PropTypes from 'prop-types'

const CustomButton = ({ children, type, onClick, loading, big }) => {
	return (
		<button
			type={type}
			className={`rounded-xl ${
				big ? 'px-10 py-2 text-lg rounded-lg' : 'px-4 py-2'
			}  m-2 ${
				loading
					? 'border-blue cursor-not-allowed'
					: 'focus:outline-none hover:text-white bg-blue hover:bg-grey hover:border-blue '
			} border text-black font-black `}
			disabled={loading}
			onClick={onClick}>
			{children}
		</button>
	)
}

CustomButton.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	onClick: PropTypes.func,
	loading: PropTypes.bool,
	type: PropTypes.oneOf(['button', 'submit']),
}

CustomButton.defaultProps = {
	children: null,
	className: '',
	onClick: () => {},
	loading: false,
	type: 'button',
}

export default CustomButton
