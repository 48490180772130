import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import TextField from './TextField'
import CustomButton from './CustomButton'
import { createUser } from '../redux/actions/auth'
import { Link } from 'react-router-dom'

const RegForm = ({ order, play }) => {
	const dispatch = useDispatch()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [name, setName] = useState('')
	const [address, setAddress] = useState('')
	const [phone, setPhone] = useState('')
	const [tax, setTaxNumber] = useState('')
	const [zip, setZip] = useState('')
	const [city, setCity] = useState('')

	const validateAndSend = () => {
		if (email === '' || password === '' || name === '' || address === '' || phone === '' || zip === '' || city === '') {
			alert('Az egyik mezőt üresen hagytad!')
		} else {
			dispatch(createUser(email, password, name, address, phone, tax, zip, city))
		}
	}

	return (
		<div className='mt-12 w-full lg:w-5/12 flex flex-col items-center'>
			<h2 className='text-2xl font-bold'>Regisztráció</h2>
			<TextField type='text' label='E-mail cím*:' name='email' value={email} onChange={(event) => setEmail(event.target.value)} />
			<TextField type='password' label='Jelszó*:' name='password' value={password} onChange={(event) => setPassword(event.target.value)} />
			<TextField type='text' label='Név*:' name='name' value={name} onChange={(event) => setName(event.target.value)} />
			<TextField type='text' label='Irányítószám*:' name='zip' value={zip} onChange={(event) => setZip(event.target.value)} />
			<TextField type='text' label='Város*:' name='city' value={city} onChange={(event) => setCity(event.target.value)} />
			<TextField type='text' label='Cím*:' name='address' value={address} onChange={(event) => setAddress(event.target.value)} />
			<TextField type='text' label='Telefonszám*:' name='phone' value={phone} onChange={(event) => setPhone(event.target.value)} />
			<TextField type='text' label='Adószám (cégeknek):' name='tax' value={tax} onChange={(event) => setTaxNumber(event.target.value)} />
			{!order && !play && (
				<div>
					Regisztrációval elfogadom az{' '}
					<Link className={'underline'} to='/ászf'>
						ÁSZF-et
					</Link>{' '}
					és az{' '}
					<Link className={'underline'} to='/adatkezelési'>
						Adatkezelési tájékoztatót
					</Link>
				</div>
			)}
			<CustomButton big onClick={() => validateAndSend()}>
				{order || play ? 'Tovább' : 'Küldés'}
			</CustomButton>
		</div>
	)
}

export default RegForm
