import React, { useState } from 'react'

export const RollImages = ({ img, title }) => {
	const imgArray = img.split(';').map((i) => {
		return i.trim()
	})
	const [image, setImage] = useState(imgArray[0])

	return (
		<div className='flex flex-col lg:flex-row'>
			<div className='flex flex-col '>
				{imgArray.map((img) => (
					<div
						key={img}
						className={`w-90 p-1 rounded lg:w-28 lg:${
							image !== img && 'opacity-50'
						} lg:hover:opacity-100`}
						onClick={() => setImage(img)}>
						<img className='rounded-lg' src={img} alt={title} />
					</div>
				))}
			</div>
			<div className='hidden lg:block mx-auto'>
				<img
					src={image}
					alt={title}
					className='p-1 object-contain rounded-lg lg:max-h-src80'
				/>
			</div>
		</div>
	)
}
