import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import Layout from '../components/Layout'
import LoginForm from '../components/LoginForm'
import RegForm from '../components/RegForm'
import { getCookie } from '../utils/cookies'

const Login = () => {
	const history = useHistory()
	const reduxtoken = useSelector((state) => state.auth.token)
	const token = getCookie('token')

	useEffect(() => {
		if (token) {
			history.push('/profil')
		}
	}, [token, reduxtoken])

	return (
		<Layout active='Profil'>
			<div className='mx-24'>
				<div className='flex flex-col lg:flex-row justify-between'>
					<LoginForm />
					<div className='my-0 lg:my-20 h-auto bg-white w-px rounded-full' />
					<RegForm />
				</div>
			</div>
		</Layout>
	)
}

export default Login
